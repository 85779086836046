import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from '../../utils/theme';
import { Normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
body {
  ${theme.font.antic};
  color: #c8c7e2;
  padding: 0;
  margin: 0;
  user-select: none;
  background: linear-gradient(to right top, #130111, #140112, #160113, #170114, #180115, #190216, #1a0316, #1b0417, #1d0618, #1f0919, #200b1a, #220d1b);
  }

  p {
  font-size: 1.1rem;
  font-weight: 600;
  opacity: .8;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
  
    html {
    padding: 0;
    margin: 0;
    background: black;
}
  }
`;

const Layout = ({ children, props }) => (
  <ThemeProvider theme={theme}>
    <Normalize />
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

export default Layout;