import { colors } from './colors';

export const theme = {

  colors,

  responsiveImg: 'height: 100%; width: 100%; background-size: cover',

  hover: {
    text: "color: skyblue; -webkit-text-stroke: 1px #040026"
  },

  font: {
    gloria: "font-family: 'Gloria Hallelujah'; url(Gloria Hallelujah); color: #040026",
    indie: "font-family: 'Indie Flower'; color: #040026",
    antic: "font-family: 'Antic Didone'"
  },

  centered: "position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%)",
  flex: {
    contentLeft: "display: flex; flex-direction: column; justify-content: flex-start; align-items: center",
    centered: "display: flex; justify-content: center; align-items: center",
    centeredColumn: "display: flex; flex-direction: column; justify-content: center; align-items: center"
  },

  media: {
    ultraWide: '@media (min-width: 1920px)',
    maxCloud: '@media (min-width: 1524px)',
    cloud: '@media (max-width: 1524px)',
    minDesktop: '@media (min-width: 1248px)',
    desktop: '@media (max-width: 1048px)',
    tabletPro: '@media (max-width: 900px)',
    tablet: '@media (max-width: 728px)',
    mobile: '@media (max-width: 576px)',
    iphone5: '@media (max-width: 360px)',
  },

  buttonMain: 'display: inline-block; background: #1b0917; color: #c8c7e2; text-decoration: none; font-size: 1.5rem;font-weight: 900; text-transform: uppercase; margin: 25px 25px 25px 0; padding: 10px 30px; border-radius: 10px; border: 1px solid #c8c7e2; @media not all and (hover: none){ &: hover { background: #c8c7e2; color: #1b0917; }} &:active {background: #c8c7e2; color: #1b0917;} @media (max-width: 576px) { margin-right: 0; }',

  buttonSubpage: 'border: 1px solid ; display: inline-block; background: #c8c7e2; color: #1b0917; text-decoration: none; font-size: .6rem; font-weight: 900; text-transform: uppercase; margin: 25px 25px 25px 0; padding: 0px 30px; border-radius: 10px; line-height: 1; @media not all and (hover: none){ &: hover { background: #1b0917; color: #c8c7e2; }} &:active {background: #1b0917; color: #c8c7e2;} @media (max-width: 576px) { margin-right: 0; }'
}